<script setup>
import { useModal, VueFinalModal, useVfm } from 'vue-final-modal'
const vfm = useVfm()

const closeModal = () => {
  vfm.close('ModalTermsAndConditions')
}
</script>

<template>
  <LayoutModal modal-id="ModalTermsAndConditions" :is-scroll="true">
    <template v-slot:title>
      <h1 class="text-head4 modal-title text-black">
        Website Terms and Conditions
      </h1>
      <p class="text-caption2 modal-date text-gray4">LAST UPDATED: AUGUST 12, 2022</p>
    </template>
    <article class="modal-content terms">
      <p class="terms-text text-gray4 text-body2">
        These terms and conditions ("Terms") apply to your use and access of web sites owned and operated by Letitu Corp, including, but not limited to, www.diveintothepond.com. By using a Letitu Site and/or creating an account on a Letitu Site, you accept these Terms. Letitu may revise these Terms at any time by updating them as they are displayed on the Letitu Sites. We are not required to contact you prior to changing these Terms. You should visit this page periodically to review these Terms, because they are binding on you. The terms "You" and "User" as used herein refer to all individuals or entities accessing this Letitu Site.
      </p>
      <h2 class="terms-sub-title text-black text-head3">
        USE OF LETITU SITES
      </h2>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          1. PERMITTED USE AND USE RESTRICTIONS
        </p>
        <p class="whitespace-pre-wrap">
          Letitu authorizes you to view and access Letitu Sites solely for your personal, non-commercial use to aid in your college search, application and matriculation process ("Permitted Use"). Your Permitted Use of the Letitu Sites is conditioned upon the following restrictions:

          You agree that you will not, under any circumstance,

          post any information that is abusive, threatening, obscene, defamatory, libelous, racially, sexually, religiously, or otherwise objectionable and offensive;

          use any Letitu Site for any purpose that violates any applicable U.S. federal, state or foreign law;

          attempt to or harass, abuse or harm another person or group in any way explicitly or implied;

          make any automated use of the website, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;

          bypass any robot exclusion headers or other measures we take to restrict access to the website or use any software, technology, or device to scrape, spider, or crawl the website or harvest or manipulate data;

          copy or adapt any of the Web Site Content (defined below) or the underlying code with respect to any portion of any Letitu Site or the content appearing therein;

          use any Letitu Site to violate the privacy (e.g., by improperly accessing Personal Information) or infringe the intellectual property rights of any third party;

          use any device, software or routine to interfere or attempt to interfere with the proper working of any Letitu Site;

          use or attempt to use any engine, software, tool, agent or other device or mechanism (including, customized browsers, spiders, robots, avatars or intelligent agents) to navigate or search any Letitu Site other than the search engine and software available from Letitu on such Letitu Site and other than generally available third party web browsers;

          attempt to decipher, decompile, disassemble or reverse engineer any of the software or algorithm related to any Letitu Site;

          attempt to compromise the security or privacy of another Letitu user's personal information;

          aggregate, copy or duplicate any of the Web Site Content or information available from any Letitu Site;

          intercept or access data the user is not authorized to access;

          attempt to scan the vulnerability of a system or network or to breach security or authentication measures without Letitu's prior express written authorization;

          create a false identity, misrepresent your identity, impersonate another person, create a profile for anyone other than yourself (a real person), or use or attempt to use another person’s account; or

          attempt to interfere with service to any user, host or network, including transmitting a virus to any Letitu Site or similar malicious activity.
        </p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          2. PROPRIETARY RIGHTS
        </p>
        <p class="whitespace-pre-wrap">You acknowledge and agree that Letitu, and our licensors, retain ownership of all intellectual property rights of any kind related to the Letitu Sites. This shall include applicable copyrights (including text, images, graphics, logos, links, button icons, software and other content), trademarks and other proprietary rights (collectively, "Web Site Content"). Other product, company or educational institution names that are mentioned on the Letitu Sites may be trademarks of their respective owners. Use of any Letitu trademark or any other trademark listed is prohibited without express written consent.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          3. ONLINE CONTENT DISCLAIMER
        </p>
        <p class="whitespace-pre-wrap">Opinions, advice, statements, offers, or other information or content made available through the Letitu Sites, whether orally, graphically, in writing or otherwise, should not necessarily be relied upon. Authors of such information are solely responsible for such content. The Company does not guarantee the accuracy, completeness, or usefulness of any information on the Letitu Sites and neither does the Company adopt nor endorse, nor is the Company responsible for, the accuracy or reliability of any opinion, advice, statement or information made or provided by parties other than the Company. The Company takes no responsibility and assumes no liability for any content on the website. Under no circumstances will the Company be responsible for any loss or damage resulting from your reliance on information or other content posted on the website or transmitted to you.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          4. PRIVACY POLICY
        </p>
        <p class="whitespace-pre-wrap">Letitu respects the privacy of website users. Please refer to the Company’s Privacy Policy (found here) which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Letitu Sites, you signify your agreement to this Privacy Policy.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          5. USER CONTENT
        </p>
        <p class="whitespace-pre-wrap">Through the use of Letitu Sites, you may, from time to time, submit, upload, publish, or otherwise make available to Letitu, your content, reviews, feedback, and other information ("User Content"). Any User Content provided by you remains your property, and any Personal Information that you submit as part of User Content shall be subject to Letitu’s Privacy Policy, referenced above.

          You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases necessary to deliver the User Content to Letitu and to grant Letitu the license to the User Content as set forth below; and (ii) neither the User Content, nor your submission, uploading, publishing or otherwise making available of such User Content, nor Letitu’s use of the User Content as permitted herein will infringe, misappropriate, or violate a third party's intellectual property or proprietary rights, or rights of personality, publicity or privacy, or any moral rights, or result in the violation of any applicable law or regulation.

          You agree not to provide User Content that (i) is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, deceptive, or otherwise offensive, as determined by Letitu in its sole discretion, whether or not such material may be protected by law, (ii) advertises funds, goods or services or contains viruses or other material that may damages another's computer, (iii) infringes any patent, trademark, trade secret, copyright, right of privacy or publicity, or other proprietary rights of any person or entity; or (iv) otherwise violates any of the restrictions set forth in the section of these Terms titled "Permitted Use and Use Restrictions". Letitu may, at its sole discretion, review, monitor, or remove User Content, at any time and for any reason, without notice to you. However, Letitu is under no obligation to review, monitor or remove User Content.

          Finally, by providing User Content, you grant Letitu and its affiliates a perpetual, royalty-free, irrevocable and unrestricted worldwide right and license to use, reproduce, modify, adapt, publish, record, translate, create derivative works from and distribute the User Content or incorporate the User Content into any form, medium, or technology now known or later developed, for any purposes.
        </p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          6. RESTRICTED MATTERS
        </p>
        <p class="whitespace-pre-wrap">Letitu prohibits Users from engaging in any of the following acts when using the Services:
          6.1.Acts that violate the laws and regulations, court verdicts, resolutions or orders, or administrative measures that are legally binding;
          6.2.Acts that may be in violation of public order, morals or customs;
          6.3.Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights, rights to fame, privacy, and all other rights granted by law or by a contract with Letitu and/or a third party;
          6.4.Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions that amount to child pornography or child abuse; expressions that lead to discrimination by race, national origin, creed, gender, social status, family origin, etc.; expressions that induce or encourage suicide, self-injurious behavior or drug abuse; or expressions that include anti-social content and lead to the discomfort of others;
          6.5.Acts that lead to the misrepresentation of Letitu and/or a third party or that intentionally spread false information;
          6.6.Acts of sending the same or similar messages to a large, indefinite number of Users (except for those approved by Letitu), indiscriminately adding other Users as friends or to group chats, or any other acts deemed by Letitu to constitute spamming;
          6.7.Acts of exchanging the right to use the Services or Contents into cash, property or other economic benefits, other than by using the method prescribed by Letitu;
          6.8.Acts of using the Services for sales, marketing, advertising, solicitation or other commercial purposes (except for those approved by Letitu); using the Services for the purpose of sexual conduct or obscene acts; using the Services for the purpose of meeting or engaging in sexual encounters with an unknown third party; using the Services for the purpose of harassment or libelous attacks against other Users; or otherwise using the Services for purposes other than as intended by the Services;
          6.9.Acts that benefit or involve collaboration with anti-social groups;
          6.10.Acts that are related to religious activities or invitations to certain religious groups;
          6.11.Acts of unauthorized or improper collection, disclosure, or provision of any other person's personal information, registered information, user history, or the like;
          6.12.Acts of interfering with the servers and/or network systems of the Services; fraudulently manipulating the Services by means of bots, cheat tools, or other technical measures; deliberately using defects of the Services; making unreasonable inquires and/or undue claims such as repeatedly asking the same questions beyond what is necessary, and other acts of interfering with or hindering Letitu's operation of the Services or other Users’ use of the Services;
          6.13.Acts of decoding the source code of the Services, such as by way of reverse engineering, disassembling or the like, for unreasonable purposes or in an unfair manner;
          6.14.Acts that aid or encourage any acts stated in Clauses 13.1 to 13.13 above; and
          6.15.Acts other than those set forth in Clauses 13.1 to 13.14 that Letitu reasonably deems to be inappropriate.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          7. AGE REQUIREMENT
        </p>
        <p class="whitespace-pre-wrap">Letitu Sites and the services offered on them are available to individuals age 13 and over. If you are between the ages of 13 and the age of majority where you live, you must review these Terms with your parent or guardian to confirm that you and your parent or guardian understand and agree to it.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          8. PASSWORD
        </p>
        <p class="whitespace-pre-wrap">When you register at certain Letitu Sites, you may be asked to create a password for future access to your Letitu Site account. You must maintain the confidentiality of your password as you are responsible for all activity that occurs under your account when authenticated with your password. You agree to notify Letitu immediately of any unauthorized use of your account or password, or any other breach of security. You will be able to permit others to access your account and information, such as a school counselor or advisor, and each person will set their own password the first time they access your account. Your granting of permission to these other users is at your own risk and Letitu is not responsible for any action taken by those other users. If you lose your password, Letitu may email your password to the email address in our records and associated with your account. Letitu is not liable for any misuse as a result thereof.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          9. VIOLATION OF THESE TERMS
        </p>
        <p class="whitespace-pre-wrap">Any violation of these Terms as determined by Letitu in its sole discretion may result in the immediate denial of access to the Letitu Sites. Letitu may take these actions at any time, in our sole discretion, with or without notice, and without any liability to the account holder or user whose access is blocked or terminated.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          10. INTERACTION WITH OTHER USERS
        </p>
        <p class="whitespace-pre-wrap">The Letitu Sites may contain chat rooms, public posting forums, interactive live events, and other forums and features permitting direct communications with other users and Letitu advisors (each a "Public Access Area" and collectively the "Public Access Areas"). Any information provided by you in Public Access Areas is public. Letitu is not responsible for any information posted and/or viewed in Public Access Areas. You may not post content to Public Access Areas that violates any applicable law, contains offensive or abusive content, infringes any third party intellectual property rights, advertises or solicits funds, goods or services, contains viruses or other material that may damages another's computer, or otherwise violates any of the restrictions set forth in the section of these Terms titled "Permitted Use and Use Restrictions".

          Letitu may remove any content from any Public Access Area at any time, without notice. By posting in Public Access Areas any content authored or owned by you, you automatically waive all rights to any intellectual property that may be contained in such posting and, if for any reason that waiver is determined to be invalid, you grant to Letitu and its affiliates a perpetual, royalty-free, irrevocable and unrestricted worldwide right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute the posted content or incorporate the posted content into any form, medium, or technology now known or later developed, for any purposes.

          The Letitu Sites may also contain features permitting you to post content to the Sites for private review and feedback by other users and by Letitu personnel, such as peer review of essays (each a "Private Review Area" and collectively the “Private Review Areas”). Letitu uses algorithms to automatically match requests for review of content to potential reviewers. Letitu is not responsible for any information posted, viewed, reviewed or commented on using the Private Review Areas. You may not post content to the Private Review Areas that violates any applicable law, contains offensive or abusive content, infringes any third party intellectual property rights, advertises or solicits funds, goods or services, contains viruses or other material that may damages another's computer, or otherwise violates any of the restrictions set forth in the section of these Terms titled "Permitted Use and Use Restrictions". All content posted to the Private Review Areas must be your original materials and ideas and must not infringe the intellectual property rights of others.

          You understand that by posting your content to either or both the Public Access Areas and Private Review Areas, a user could plagiarize your materials. While this is a violation of our community guidelines and these Terms, Letitu has no control over such activity and disclaims any obligation to police, monitor or take any action relating to such plagiarism. Further, while submission of content to the Public Access Areas and/or Private Review Areas does not require you to provide any personal information, you acknowledge that your content may include information that might expose your identity as a result of such content. If you are concerned about such exposure you should take efforts to modify your content so you are not potentially subject to such exposure or you should consider whether or not use of the Public Access Areas and/or Private Review Areas is appropriate for you.

          You further acknowledge that additional terms and conditions may apply to the use of the Public Access Areas and Private Review Areas, including frequency of use of the Public Access Areas or Private Review Areas and community guidelines relating to plagiarism, appropriate content, and helpful feedback. We reserve the right to restrict your ability to access the Public Access Areas and Private Review Areas for any reason, with or without notice, including for violation of these Terms or the community guidelines.

          By using the Public Access Areas and Private Review Areas, you grant to Letitu the right to use your content to improve the services and features offered by Letitu. We may, among other uses, utilize your content in an aggregated, anonymized manner to implement and develop features for the site and to develop metrics based on your usage of these features.

          Letitu disclaims all responsibility for the quality, timeliness, appropriateness and value of any content, information or review provided to you through the Public Access Areas and Private Review Areas and the use of your content by reviewers of content. You agree that you are using the Public Access Areas and Private Review Areas at your sole risk.
        </p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          11. LETITU NETWORK
        </p>
        <p class="whitespace-pre-wrap">The Letitu Sites may also contain features that facilitate your connection and communication with selected colleges, universities, other educational institutions, and/or representatives from any of these institutions (“Letitu Network”). Letitu Network serves as an intermediary in order to facilitate these connections. When you opt to connect with an educational institution, you agree to share your profile information with that educational institution.

          You agree that you will only submit true, correct, and original profile information. You acknowledge that, subject to the restrictions set forth herein, it is your decision as to whether or not to share your profile. You may not post content that violates any applicable law, or infringes any patent, trademark, trade secret, copyright, right of privacy or publicity, or other proprietary rights of any person or entity.

          You understand that your use of Letitu Network is not a guarantee that an educational institution will admit you. Letitu takes no responsibility for the decisions made by the institutions. Letitu only serves as the coordination platform to connect you to educational institutions to engage in discussions with admissions officers and other stakeholders in the admission process. The decisions surrounding your admittance, enrollment, and financial aid packages, are entirely at the discretion of the educational institution and you. Letitu provides no guarantee that you will be accepted, that you will enroll, or that the final tuition bill will be reduced at the institution at which you choose to matriculate. Letitu has no liability if your correspondence with the educational institution is not received or is only partially received. It is your responsibility to confirm with the educational institution that your correspondence has been received.

          You further acknowledge that additional terms and conditions may apply to the use of Letitu Network, including receiving notification and marketing purpose materials, community guidelines, appropriate behavior, and helpful feedback. We reserve the right to restrict your ability to access Letitu Network for any reason, with or without notice, including for violation of these Terms or the community guidelines.

          By using Letitu Network, you grant to Letitu the right to use your content to improve the services and features offered by Letitu. We may, among other uses, utilize your content in an aggregated, anonymized manner to implement and develop features for the site and to develop metrics associated with Letitu Network.
        </p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          12. NO WARRANTIES
        </p>
        <p class="whitespace-pre-wrap">Letitu Sites are a venue for students and their parents to learn about colleges and universities, the admissions process, financial aid alternatives, and other college admissions topics and for representatives of colleges and universities to learn about and interact with students. Letitu does not control, and is not involved in, admissions or financial aid decisions or negotiations or the admission process between colleges, universities and students. Letitu is not responsible for transactions with advertisers appearing on a Letitu Site or in newsletters or between students and representatives from colleges and universities.

          The Web Site Content may contain inaccuracies or typographical errors. Letitu makes no representations about the accuracy, reliability or completeness, of any Letitu Site or the Web Site Content or the timeliness of any services therein provided. The use of the Letitu Sites and the Web Site Content is at your own risk. Changes are periodically made to the Letitu Sites. While we will endeavor to make these changes at a time least likely to interfere with your use of any Letitu Site, such changes may be made at any time.

          Certain Web Site Content may contain information about paying for college, financial aid and related tax or legal consequences. You understand that this content is provided for informational purposes only and is not a substitute for professional advice from qualified practitioners. You should always seek professional advice tailored to your individual circumstances.

          Certain portions of the Web Site Content may indicate to you your likelihood of admission to a particular college or university. Such services are for directional purposes only and should not be relied upon in your college search process. Always talk to experts such as college admissions personnel and high school counselors if you are unsure of your chances of admission to a particular college or university. You assume all risks regarding your use of this tool and understand that any decision to apply or not to apply to any particular college or university and the resulting consequences are your sole responsibility.

          THE LETITU SITES, ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, LETITU EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, REGARDING THE LETITU SITES INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, LETITU MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE LETITU SITES WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE LETITU SITES. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.
        </p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          13. LIMITATION OF DAMAGES
        </p>
        <p class="whitespace-pre-wrap">TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL LETITU, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS USE OR DATA, OR FOR ANY DIRECT, INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM (A) YOUR USE OR INABILITY TO USE THE LETITU SITES OR THE WEB SITE CONTENT; (B) THE LETITU SITES GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE LETITU SITES AVAILABLE; OR (C) ANY OTHER INTERACTIONS WITH LETITU OR BETWEEN YOU AND ANY THIRD PARTY THROUGH THE LETITU SITES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LETITU HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          14. LINKS TO OTHER SITES AND/OR MATERIALS
        </p>
        <p class="whitespace-pre-wrap">On the Letitu Sites, Letitu may provide you with convenient links to third party website(s) (“Third Party Sites”) as well as content or items belonging to or originating from third parties (the “Third Party Applications, Software or Content”). These links are provided as a courtesy to Letitu Site users. Letitu has no control over Third Party Sites and Third Party Applications, Software or Content or the promotions, materials, information, goods or services available on these Third Party Sites or Third Party Applications, Software or Content. Such Third Party Sites and Third Party Applications, Software or Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness by Letitu, and Letitu is not responsible for any Third Party Sites accessed through the Letitu Sites or any Third Party Applications, Software or Content posted on, available through or installed from the Third Party Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or contained in the Third Party Sites or the Third Party Applications, Software or Content. Inclusion of, linking to or permitting the use or installation of any Third Party Site or any Third Party Applications, Software or Content does not imply approval or endorsement thereof by Letitu. If you decide to leave the Letitu Sites and access the Third Party Sites or to use or install any Third Party Applications, Software or Content, you do so at your own risk and you should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from the Letitu Sites or relating to any applications you use or install from the site.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          15. NO RESALE OR UNAUTHORIZED COMMERCIAL USE
        </p>
        <p class="whitespace-pre-wrap">You agree not to resell or assign your rights or obligations under these Terms and any purported assignment is null and void. You also agree not to make any unauthorized commercial use of any Letitu Site.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          16. INDEMNITY
        </p>
        <p class="whitespace-pre-wrap">You agree to defend, indemnify, and hold harmless Letitu, its members, officers, directors, employees and agents, from and against any claims, actions or demands, including reasonable legal and accounting fees, arising out of the use of any Letitu Site by you or any account you created. Letitu will provide notice to you promptly of any such claim, suit, or proceeding and will assist you, at your expense, in defending any such claim, suit or proceeding. Letitu reserves the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this section. In such event, you must provide Letitu with any cooperation Letitu reasonably requests.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          17. MODIFICATION OF TERMS
        </p>
        <p class="whitespace-pre-wrap">These Terms may be amended at any time and from time to time, by a revised posting on this page and updating the Last Updated date. We do not undertake any obligation to otherwise notify you of such amendment. If you continue to use the Letitu Sites, you signify your agreement to our revisions to these Terms. All provisions of these Terms that by their nature must survive the termination of your use of a Letitu Site will survive, including the Limitation on Damages and Indemnity.</p>
      </div>
      <div class="terms-text text-gray6 text-body2">
        <p class="terms-sub-text text-body1-b text-black">
          18. GENERAL TERMS
        </p>
        <p class="whitespace-pre-wrap">If any part of these Terms is held invalid or unenforceable, that portion of the Terms will be construed consistent with applicable law. The remaining portions will remain in full force and effect. Any failure on the part of Letitu to enforce any provision of these Terms will not be considered a waiver of our right to enforce such provision. Our rights under these Terms will survive any termination of these Terms.

          You agree that any cause of action related to or arising out of your relationship with Letitu must commence within one year after the cause of action accrues. Otherwise, such cause of action is permanently barred.

          These Terms and your use of the Letitu Sites are governed by the laws of California, without regard to conflict of law provisions.

          Letitu makes no claims that any Letitu Site or the Web Site Content may be lawfully viewed or accessed outside of the United States. If you access a Letitu Site from outside of the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction.

          Letitu may assign or delegate these Terms and/or Letitu’s Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms or Privacy Policy without Letitu’s prior written consent, and any unauthorized assignment and delegation by you is void.

          YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THE TERMS, AND WILL BE BOUND BY THESE TERMS. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS TOGETHER WITH THE PRIVACY POLICY AT WWW.IUBENDA.COM/PRIVACY-POLICY/18448319 REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THESE TERMS.
        </p>
      </div>
    </article>
  </LayoutModal>
</template>

<style scoped lang="scss">
.terms-text {
  padding: 20px 24px;
}
.terms-sub-title {
  padding: 40px 24px 20px;
}
.terms-sub-text {
  margin-bottom: 12px;
}
</style>
